.MdFullScreenContainer .ModaFulllTitle {
    margin: 0 !important;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #0B132B !important;
  }

  .MdFullScreenContainer{
    
  }

  .FooterButtonContainer{
    display: flex;
    justify-content: center;
  }

 .FooterButtonContainer .MuiButtonBase-root{
    margin-right: 15px;
  }
  
 .FooterButtonContainer .MuiButtonBase-root{
    margin-right: 15px;
    height: 38px;
    box-shadow: none;
    font-size: 14px;
  }
  
 .FooterButtonContainer .btLight{
    color: #004fe0;
    background: #fff; 
    border: #E4E8EC 1px solid;
    border-radius: 10px !important;
    text-transform: initial !important;
    padding: 10px 15px;
    letter-spacing:0 !important;
  }
  
 .FooterButtonContainer .btLight:hover{
    color: #676C7B;
    background: #fff; 
    border: #E4E8EC 1px solid;
  } 
 .FooterButtonContainer .btnBlue {
    color: #fff;
    background: #004FE0;
    border: #004FE0 1px solid;
    border-radius: 10px !important;
    text-transform: initial !important;
    padding: 10px 15px;
}
  
.FooterButtonContainer .btnBlue:hover{
    color: #fff;
    background: #004FE0; 
    border: #004FE0 1px solid;
  }

  .MdFullScreenContainer .MuiDialog-paper {
    max-height: 800px !important;
    margin-top: 6% !important;
    margin-bottom: 2% !important;
}


@media (max-width: 768px) {
    .MdFullScreenContainer .MuiDialog-paper {
        border-radius: 0px !important;
        padding: 15px !important;
        max-height: initial !important;
    }

    .MdFullScreenContainer .MuiDialogContent-root{
      padding: 20px 10px;
    }


}