.DrawerContainer {
    position: relative;
    z-index: 999;
    background: #fff;
}

.DrawerHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: #E4E8EC 1px solid;
    padding: 10px 22px;
    padding-right: 0px;
    position: relative;
    z-index: 3;
    background: #fff;
    position: sticky;
    top: 0;
    align-items: center;
}

.DrawerHeader .DrawerHeaderTitle {
    font-size: 20px;
    color: #0B132B;
    font-weight: 600;
}

.DrawerHeader svg {
    color: #0B132B;
}

.DrawerHeader .MuiButtonBase-root.MuiButton-root {
    padding: 0;
}

.DrawerBody {
    padding: 32px 40px;
    width: 744px;
    overflow: auto;
    padding-bottom: 140px;
}

.DrawerFooter {
    position: fixed;
    bottom: 0;
    border-top: #E4E8EC 1px solid;
    background: #F8F8F9;
    width: -webkit-fill-available;
}

.DrawerFooterInner {
    display: flex;
    justify-content: space-between;
    padding: 18px 40px;
}

.DrawerFooterButton {
    display: flex;
    align-items: center;

}

.DrawerFooter .DrawerFooterInner .DrawerCloseButton {
    text-transform: initial;
    color: #0B132B;
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
}

.DrawerFooter .DrawerFooterInner .DrawerActionButton {
    text-transform: initial;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    background: #004FE0;
    border-radius: 8px;
    padding: 8px 20px;
    height: 48px;
}

.DrawerCloseButtonMobile {
    display: none !important;
}



.DrawerTabsContainer {
    border-bottom: #E4E8EC 1px solid;
    padding: 0 40px;
}

.DrawerTabsContainer .MuiButtonBase-root.MuiTab-root {
    padding: 0;
    margin-right: 30px;

}

.DrawerTabsContainer .MuiButtonBase-root.MuiTab-root .tabsContent {
    display: flex;
    align-items: center;
    color: #0B132B;
    text-transform: initial !important;
}

.DrawerTabsContainer .MuiButtonBase-root.MuiTab-root.Mui-selected .tabsContent {
    color: #004FE0 !important;
    text-transform: initial;
}

.DrawerTabsContainer .MuiButtonBase-root.MuiTab-root .tabsContent svg {
    margin-right: 4px;
}

.DrawerTabsContainer .MuiTabs-indicator {
    background-color: #004FE0 !important;
}

.MediaLibPanelContainer {
    margin-top: 32px;
}

.DrawerActionButton svg {
    font-size: 18px;
}

.DrawerActionButtonLeftIcon {
    margin-right: 8px;
    display: flex;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorRight{
    z-index: 999999;
}


.DrawerContainer .DrawerBody .SearchBox .MuiInputBase-input{
    padding: 11px 18px !important;
   }



/* ==== SubDrawerBody ==== */

.SubDrawerActive .MuiDrawer-paperAnchorRight {
    right: 300px !important;
}


.SubDrawerBody {
    width: 384px;
}

.SubDrawerContainer {
    background: #F8F8F9;
    height: 100%;
    position: fixed;
    border-left: #E4E8EC 1px solid;
    top: 0;
    overflow-y: auto;
    padding: 24px;
    padding-top: 90px;
    width: 384px;
}

.SubDrawer .MDC {
    display: flex;
}

.SubDrawerActive .SubDrawerBody {
    display: none;
}

.SubDrawer .DrawerFooter {
    position: fixed;
    bottom: 0;
    border-top: #E4E8EC 1px solid;
    background: #ffffff;
    width: -webkit-fill-available;
    margin-right: 399px;
}

.DrawerSummaryButton {
    color: #004FE0 !important;
    border: #E4E8EC 1px solid !important;
    font-size: 14px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 600 !important;
    padding: 10px 15px !important;
    text-transform: initial !important;
    border-radius: 8px !important;
}

.SubDrawer  .ShowSummary{
  display: none;
}

.SubDrawerActive .CloseSummary{
    display: none;
}
/* ==== SubDrawerBody ==== */



.CheckBoxTabs .MuiTabs-indicator {
    display: none;
}

.CheckBoxTabs .CheckBoxTabsTabBody .MuiBox-root {
    padding: 0;
}

.CheckBoxTabs .MuiTab-root {
    border: #E4E8EC 1px solid;
    border-radius: 8px;
    margin-right: 16px;
    flex: 1;
    width: 100%;
    padding: 16px;
    max-width: initial !important;
}

.CheckBoxTabs .RedioContents {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.CheckBoxTabs .RedioContents .RedioLebel {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
}

.CheckBoxTabs .RedioContents .RedioLebel h3 {
    margin: 0;
    font-size: 14px;
    text-transform: initial;
}

.CheckBoxTabs .MuiTab-root.Mui-selected h3 {
    color: #0B132B;
}

.CheckBoxTabs .RedioContents .RedioLebel p {
    margin: 0;
    font-size: 14px;
    text-transform: initial;
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
    text-align: left;
    color: #0B132B;
}

.CheckBoxTabs .MuiTab-root.Mui-selected {
    border: #004FE0 1px solid;
    border-radius: 8px;
    background: #F8F8F9;
}

.CheckBoxTabs .UnSelectedRedio {
    color: #ffffff;
    padding: 1px;
    border-radius: 100px;
    /* border: #A4A7B0 2px solid; */
    font-size: 12px;
}

.CheckBoxTabs .MuiTab-root.Mui-selected .UnSelectedRedio {
    display: none;
}

.CheckBoxTabs .MuiTab-root .SelectedRedio {
    display: none;
}

.CheckBoxTabs .MuiTab-root.Mui-selected .SelectedRedio {
    color: #004FE0;
    display: block;
    padding: 0;
    /* border: #004FE0 1px solid; */
    border-radius: 100px;
    font-size: 18px;
}

.CheckBoxTabs .RedioIcon {
    position: relative;
    top: -11px;
    right: -11px;
}

.CheckBoxTabs .MuiTabs-root {
    display: flex;
    overflow: visible !important;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
}

.CheckBoxTabs .MuiTabs-scroller {
    flex: 1 1 auto;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow: visible !important;
}

.CheckBoxTabs .MuiTab-root:last-child {
    margin-right: 0 !important;
}

.CheckBoxTabs .MuiTab-wrapper {
    flex-direction: initial !important;
    justify-content: left !important;
}

.CheckBoxTabsTabBody {
    padding-top: 32px;
}

.CheckBoxTabs{
    padding: 25px;
    padding-bottom: 0;
}

.SubDrawer .CheckBoxTabs{
    max-width: 744px;
    padding: 25px;
    padding-bottom: 0;
}

.DrawerHeaderTitleUnderHeader{
    font-size: 20px;
    color: #0B132B;
    font-weight: 600;
    padding-top: 20px;
    padding-left: 25px;
}

.MainDrawerWrapper .MuiBackdrop-root{
    display: none !important;
}

.MainDrawerWrapper .MuiModal-backdrop-Custom{
    background: #000;
    opacity: 0.3;
    position: fixed;
    top: 0;
    right: 744px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.DrawerBody .TableSearchField .MuiInputBase-root {
    width: 100%;
}

.DrawerBody .TableInnerHeaderLeft {
    display: block !important;
    align-items: center;
    width: 100%;
}

.DrawerBody .SearchBox .MuiFormControl-root{
    width: 98% !important;
}


@media (max-width: 480px) {
    .CheckBoxTabs .MuiTab-root {
        max-width: 114px !important;
        padding-left: 7px;
    }

    .CheckBoxTabs .RedioContents {
        flex-direction: row-reverse;
    }
}


@media (max-width: 768px) {
    .DrawerBody {
        padding: 20px;
        width: 100%;
    }

    .DrawerFooterInner {
        display: block;
        text-align: left;
    }

    .DrawerCloseButton {
        display: none !important;
    }

    .DrawerCloseButtonMobile {
        display: block !important;
        margin: 0 auto !important;
    }

    .PeronalInfocard .PeronalInfocardDetail .MuiChip-root {
        margin-top: 8px;
    }

    .TabFooter {
        margin-left: -20px;
    }

}

@media (max-width: 468px) {
    .DrawerFooter .DrawerFooterInner .DrawerActionButton {
        width: 100%;
        margin-bottom: 5px;
    }

    .DrawerFooterButton {
        display: block;
        align-items: center;
    }

    .SubDrawer .DrawerBody {
        padding: 20px;
        width: 320px;
    }
}

.DrawerContainer .DrawerBody .TextArea textarea{
   width: 100%;
}

