.PaymentMethod{
    margin-bottom: 32px;
}

.PaymentMethod .PaymentMethodTitle{
    margin-bottom: 4px;
    font-size: 14px;
    color: #676C7B;
}

.PaymentMethodCarItems .TableItemImgCell{
    display: flex;
    align-items: center;
}

.PaymentMethodCarItems .item-icon{
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.PaymentMethodCarItems .TableItemImgCell p{
    margin: 0;
    font-size: 16px;
    color: #0B132B;
    font-weight: 500;
}

.TitlewituChip{
    display: flex;
    align-items: center;
}

.TitlewituChip .StatusChip{
    margin-left: 10px;
}

.Pmt-ImgBox{
    background: #fff;
    border: #F2F0F4 1px solid;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;
}

.Pmt-ImgBox .PmtImg{
    margin-right: 12px;
    overflow: hidden;
    width: 54px;
    height: 54px;
    border-radius: 12px;
}

.Pmt-ImgBox .PmtImg img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.Pmt-ImgBox .PmtInfo p{
    margin: 0;
    font-size: 16px;
    color: #0B132B;
    font-weight: 500;
}

.Pmt-ImgBox .PmtInfo a{
    text-decoration: underline !important;
    font-size: 14px;
}