
.Datefield .MuiFormControl-root{
    width: 100%;
}

.Datefield .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd{
    padding: 13px 14px !important;
}


