
.AddField{
    background: #EBF1FD;
    border-radius: 12px;
    position: relative;
    padding: 15px;
    margin-bottom: 8px;
}

.AddField .MuiFormControl-root {
    width: 96%;
    background: #fff;
}

.AddField .RemoveField {
    position: absolute !important;
    right: -4px;
    margin-top: 4px;
    color: #676C7B;
}

.AddField .RemoveField svg{
    font-size: 22px;
}

.AddField .MuiInputBase-root {
    width: 100%;
    height: 46px;
    border-radius: 0 !important;
}

.AddField .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: #E4E8EC;
    border-radius: 0 !important;
}

.AddField .FieldCount {
    position: absolute;
    right: 62px;
    top: 28px;
}

.AddField .FieldCount span{
    font-size: 16px;
}

.TextField .AddField .MuiInputBase-input{
    padding-right: 72px;
    top: 0 !important;
}

.AddMoreButton{
    font-weight: 600 !important;
    font-size: 14px !important;
    color:#004FE0 !important;
    text-transform: initial !important;
}


.AddField   .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    top: 0 !important;
}