.HeaderContainer {
    position: relative;
    top: 0;
    z-index: 999;
    width: 100%;
    left: 0;
}

.Header {
    background: #fff;
    height: 65px;
    width: auto;
    padding: 10px 25px;
    box-shadow: 0px 1px 3px #0000001A;
    display: flex;
    justify-content: space-between;
}

.Header .Branding img {
    height: 35px;
}

.HdrRightCol {
    display: flex;
}

.Header .HdrRightCol .MuiAvatar-root {
    margin-left: 16px !important;
    border-radius: 10px;
    cursor: pointer;
}

.NotificationButton {
    border: #D2D3D8 1px solid !important;
    border-radius: 10px !important;
    width: 40px !important;
    height: 40px !important;
    min-width: initial !important;
    min-height: initial !important;
    position: relative;
}

.NotificationButton svg {
    color: #004FE0;
}

.NotificationButton .NotificationCount {
    background: #E63946;
    text-align: center;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 100px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    right: -10px;
    top: -7px;
}

/* === Dropwdown menue == */

.DropdownMenu .MuiPopover-paper {
    width: 352px;
    margin-left: -10px;
    box-shadow: 0px 2px 17px #00000017 !important;
    margin-top: 0px;
}

.ProfileSection {
    display: flex;
    align-items: center;
}

.ProfileSection h1 {
    font-size: 16px;
    color: #0B132B;
    margin: 0;
    line-height: 20px;
}

.ProfileSection p{
    margin: 0;
}

.ProfileSection p a{
    font-size: 12px;
    color: #676C7B;
    text-decoration: none;
}

.ProfileSection .MuiAvatar-root{
    margin-right: 16px;
    width: 60px;
    height: 60px;
   
}

.DropdownMenuList{
    
}

.DropdownMenu .DropdownMenuList .MuiListItem-root{
    padding: 15px 25px!important;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif;

}

.DropdownMenu .DropdownMenuList .MuiListItem-root .MuiSvgIcon-root{
    margin-right: 12px;
}

.IconMenu {
    margin-right: 10px;
    display: flex;
    align-items: center;
}
/* .IconMenu{display: none;} */


.HdrLeftCol{
    display: flex;
}

.MobileView{
    display: none;
}

/* ============= Sticku header smooth ====== */
.sticky {
    position: fixed;
    width: 100%;
    top: 5px;
    transition: all 0.5s ease;
    animation: smoothScroll 0.5s forwards;
    z-index: 999;
  }
  
  @keyframes smoothScroll {
    0% {
      transform: translateY(-142px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }
/* ============= Sticku header smooth ====== */





@media (max-width: 990px){
.DesktopView{
    display: none;
}
.MobileView{
    display: block;
}

.BrowsButton{
    text-align: center;
}

.BrowsButton .ButtonContainer {
    text-align: center;
    justify-content: center;
    display: flex;
}

.BrowsButton .MuiButtonBase-root{
    width: 90%;
    margin: 0 !important;
}


}

@media (max-width: 990px){
.HeaderContainer {
    z-index: 999;
}

}