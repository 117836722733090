.StatusChip {
    display: inline-flex;
    align-items: center;
}

.StatusInner{
    display: flex;
    align-items: center;
}

.StatusChip .MuiSvgIcon-root {
    width: 15px;
    margin-right: 4px;
}

.StatusChip .StatusChipLabel {
    font-size: 14px;
    font-weight: 500;
}

.StatusChip .Paid{
    background: #E1F3E8;
    border-radius: 4px;
    padding: 2px 5px;
    color: #0B9444;
}

.StatusChip .Paid .MuiSvgIcon-root {
    color: #0B9444;
}

.StatusChip .Failed{
    background: #FFECE0;
    border-radius: 4px;
    padding: 2px 5px;
    color: #FF6200;
}

.StatusChip .Failed .MuiSvgIcon-root {
    color: #FF6200;
}

.StatusChip .Canceled{
    background: #F7E3E3;
    border-radius: 4px;
    padding: 2px 5px;
    color: #BA1A1A;
}

.StatusChip .Canceled .MuiSvgIcon-root {
    color: #BA1A1A;
}

.StatusChip .Refund{
    background: #ECEDEF;
    border-radius: 4px;
    padding: 2px 5px;
    color: #676C7B;
}

.StatusChip .Refund .MuiSvgIcon-root {
    color: #676C7B;
}

.StatusChip .Pending{
    background: #FFF5E0;
    border-radius: 4px;
    padding: 2px 5px;
    color: #FFAA00;
}

.StatusChip .Pending .MuiSvgIcon-root {
    color: #FFAA00;
}



.StatusChip .Paid .icon-faild{display: none;}
.StatusChip .Paid .icon-canceled{display: none;}
.StatusChip .Paid .icon-refund{display: none;}
.StatusChip .Paid .icon-pending{display: none;}

.StatusChip .Failed .icon-paid{display: none;}
.StatusChip .Failed .icon-canceled{display: none;}
.StatusChip .Failed .icon-refund{display: none;}
.StatusChip .Failed .icon-pending{display: none;}

.StatusChip .Canceled .icon-paid{display: none;}
.StatusChip .Canceled .icon-faild{display: none;}
.StatusChip .Canceled .icon-refund{display: none;}
.StatusChip .Canceled .icon-pending{display: none;}

.StatusChip .Refund .icon-paid{display: none;}
.StatusChip .Refund .icon-faild{display: none;}
.StatusChip .Refund .icon-canceled{display: none;}
.StatusChip .Refund .icon-pending{display: none;}

.StatusChip .Pending .icon-paid{display: none;}
.StatusChip .Pending .icon-faild{display: none;}
.StatusChip .Pending .icon-canceled{display: none;}
.StatusChip .Pending .icon-refund{display: none;}





