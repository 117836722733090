.EditProfile{}

.EditPhotoContainer{
    display: flex;
    align-items: center;
}

.EditPhotoContainer .FieldLabelUpload{
    display: none;

}

.EditPhotoContainer .HelperText{
    display: none;
}

.EditProfile .ComboBox .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 5px 9px !important;
}

.EditProfile .MuiFormHelperText-root{
    margin-bottom: 0 !important;
}

.GooglePlaceField input{
    height: 32px !important;
}