@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
    background: #F5F8FA;
}


.MuiTypography-root {
    font-family: 'Poppins', sans-serif !important;
}

.MuiChip-label {
    font-family: 'Poppins', sans-serif !important;
}

.MuiInputBase-input {
    font-family: 'Poppins', sans-serif !important;
}

.MuiAutocomplete-option {
    font-family: 'Poppins', sans-serif !important;
}

.MuiButtonBase-root {
    font-family: 'Poppins', sans-serif !important;
}

.MuiMenu-paper .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
    font-family: 'Poppins', sans-serif !important;
}

.app-body {
    width: 70%;
    height: 400px;
    margin-left: 21%;
    padding: 20px;
}

.Section {
    background: #fff;
    border-radius: 10px;
    padding: 40px;
    margin-bottom: 20px;
}


.StapperContaier {
    display: flex;
}

.StapperBody {
    padding: 30px;
    width: 24%;
    height: fit-content;
}

.StapperBody .MuiStepper-horizontal {
    display: block !important;
}

.StapperContent {
    margin-left: 20px;
    flex: 1;
}

.MdTitle {
    color: #181C32;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.StepperButton {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 43%;
    margin: 0 auto;
    margin-left: 38%;
}

/* ====== Form Css Start ======*/
.FieldLabel {
    font-size: 14px !important;
    color: #676C7B;
    margin-bottom: 4px;
    font-weight: 400;
    display: flex;
    font-family: 'Poppins', sans-serif !important;
    height: 18px;
}

.FieldLabel .MuiSvgIcon-root {
    color: #A4A7B0;
    font-size: 22px;
    margin-left: 4px;
}

.FieldLabel span {
    color: #FF0033;
}

.SecFooter {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.HelperText {
    margin: 0;
    padding-top: 4px;
    font-size: 12px;
    color: #676C7B;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.FormBody .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: #E4E8EC;
}

.FormBody .MuiFormControl-root .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #004FE0;
}




/* ====== Form Css End ======*/

/* ====== Accourding Css Start ======*/
.MuiAccordionSummary-content .MuiTypography-root {
    font-weight: 600;
}

.accFooter {
    display: flex;
    justify-content: end;
    border-top: #e3e3e3 1px solid;
    padding: 10px 16px 0 0;
    margin-top: 24px;
    margin-left: -16px;
    margin-right: -16px;

}

.MuiAccordionDetails-root {
    padding: 8px 16px 10px !important;
}


/* ====== Accourding Css end ======*/


/* ====== Card tab Css end ======*/

.CardTabs .MuiTabs-indicator {
    display: none !important;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root {
    border: #676C7B 1px dashed;
    padding: 20px;
    margin-right: 15px;
    background: #fff;
    border-radius: 4px;
    min-width: 312px;
    font-weight: 600;
    font-size: 14px;
}
.CardTabs .TabautoWidth .MuiTabs-root .MuiButtonBase-root {
    border: #676C7B 1px dashed;
    padding: 20px;
    margin-right: 15px;
    background: #fff;
    border-radius: 4px;
    min-width: auto;
    font-weight: 600;
    font-size: 14px;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root.Mui-selected {
    border: #009EF7 1px dashed;
    padding: 20px;
    margin-right: 15px;
    background: #F1FAFF;
    color: #009EF7;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root .CardTabsbutton {
    display: flex;
    align-items: center;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root .CardTabsbutton svg {
    color: #D9DAE1;
    margin-right: 15px;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root.Mui-selected .CardTabsbutton svg {
    color: #009EF7;
}

/* ====== Card tab  Css end ======*/


/* ====== Staper Css Start ======*/
.FormStepper {
    padding-bottom: 150px;
}


.StapperContaier .MuiStepLabel-iconContainer {
    border-radius: 6px !important;
    display: inline-block;
    background: #A4A7B0;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
}

.StapperContaier .MuiStepLabel-labelContainer {
    padding-left: 8px;
}

.StapperContaier .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 16px !important;
    color: #676C7B !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif;
}

.StapperContaier .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-completed {
    font-size: 16px !important;
    color: #0B132B !important;
    font-weight: 600 !important;
}

.StapperContaier .MuiStepLabel-iconContainer svg {
    color: #A4A7B0;
}

.StapperContaier .MuiStepLabel-iconContainer .Mui-active {
    color: #004FE0;
    position: relative;
}

.StapperContaier .MuiStepLabel-iconContainer .Mui-active {
    border-radius: 6px !important;
    display: inline-block;
    background: #004FE0;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed {
    background: #004FE0;
    position: relative;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed svg {
    color: #fff;
    display: none;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background: url(../imgs/check_black.svg);
    top: 3px;
    left: 5px;
}

.StapperContaier .MuiStepConnector-horizontal {
    display: none !important
}

.StapperContaier .MuiStep-root {
    margin-bottom: 24px;
    position: relative;
    cursor: pointer;
    padding: 8px;
}

.StapperContaier .MuiStep-root:hover {
    background: #f7f7f7;
    border-radius: 8px;
}

.StapperContaier .MuiStepLabel-horizontal.Mui-disabled{
    cursor: pointer !important;
}

.StapperContaier .MuiStep-root:after {
    content: '';
    border-left: 1px dashed #d7dae3;
    position: absolute;
    height: 38px;
    width: 5px;
    left: 23px;
}

.StapperContaier .MuiStep-root:last-child:after {
    border-left: none;
}

.SpperButtonContainer {
    position: fixed;
    left: 14%;
    bottom: 0;
    background: #fff;
    width: 86%;
    display: flex;
    justify-content: center;
}

.SpperButtonContainer .StepperButton .ButtonContinue {
    background: #004FE0 !important;
    color: #fff !important;
    box-shadow: none !important;
    height: 48px !important;
    border-radius: 8px !important;
    font-weight: 400;
    text-transform: initial !important;
    font-size: 14px;
    padding: 0 20px;
}


.SpperButtonContainer .StepperButton .ButtonContinue svg {
    font-size: 17px;
    margin-left: 8px;
}

/* ====== Staper Css end ======*/


/* ====== Accordion 3 Step start ======*/

.AccrodionThreeStep .MuiPaper-root {
    background: #fff;
    border: #fff 1px solid;
    border-radius: 12px !important;
    margin-bottom: 8px;
    min-height: 96px;
    box-shadow: 0px 0px 6px #00000017 !important;


}


.AccrodionThreeStep .AccrodionInner .MuiPaper-root {
    background: #F4F7FD;
    box-shadow: none !important;
    border: #E4E8EC 1px solid;
    border-radius: 10px !important;
    margin-bottom: 15px;


}

.AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiPaper-root {
    background: #fff;
    box-shadow: none !important;
    border: #E4E8EC 1px solid;
    border-radius: 10px !important;
    margin-bottom: 15px;
}

.AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(180deg) !important;
    color: #004FE0;
}

.AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiAccordionSummary-expandIconWrapper {
    position: initial;
}

.AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiAccordionSummary-content {
    margin-left: 0px !important;
}


.SessionListing li {
    background: #fff;
    padding: 16px;
    border-radius: 12px;
    border: #E4E8EC 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.SessionListing li .Sessionleftcol {
    display: flex;
    align-items: center;
}

.SessionListing li .Sessionleftcol .SessionrIcon span {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    background: #E4E8EC;
    color: #676C7B;
    padding: 4px;
    margin-right: 12px;
}



.SessionListing li .Sessionleftcol .SessionListingDetail h4 {
    margin: 0;
    font-size: 16px;
    color: #0B132B;
    font-weight: 600;
}

.SessionListing li .Sessionleftcol .SessionListingDetail p {
    margin: 0;
    font-size: 14px;
    color: #676C7B;
    font-weight: 400;
}

.SessionListing li .Sessionleftcol .SessionListingDetail a {
    margin: 0;
    font-size: 14px;
    color: #676C7B;
    font-weight: 400;
}

.SessionListing li .Sessionleftcol .SessionListingDetail p svg {
    font-size: 8px;
}

.SessionListing li .SessionListingAction svg {
    color: #676C7B;
}

.SessionListing li .SessionListingAction .lstbtn {
    background: #fff;
    border: #e1e1e7 1px solid;
    border-radius: 10px;
    color: #004FE0;
    font-weight: 600;
    font-size: 14px;
}

.SessionListing li .SessionListingAction .lstbtn svg {
    color: #004FE0;
    font-size: 20px;
    margin-right: 8px;
}



.AccrodionThreeStep .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    left: 10px;
}

.AccrodionThreeStep .MuiAccordionSummary-content {
    margin-left: 25px !important;
}



.AccrodionThreeStep .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg) !important;
    color: #004FE0;
}

.accTitleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.SessionButton {
    background: #004FE0 !important;
    border-radius: 8px !important;
    height: 40px;
    color: #fff !important;
    padding: 0 15px !important;
    text-transform: initial !important;
}

.SessionButton svg {
    color: #fff !important;
    font-size: 20px;
    margin-right: 6px;

}

.SessionButtonEdit {
    color: #004FE0 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    text-transform: initial !important;
}

.SesstionStatus {
    margin-right: 10px;
}

.SmallDot {
    font-size: 8px !important;
}

.accTitleSectionIcon {
    display: flex;
    align-items: center;
}

.accTitleSectionIcon h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.accTitleSectionIcon p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #676C7B;
}

.accTitleSectionIcon svg {
    color: #e5e5e5;
    margin-right: 10px;
}

.ScardDetails {
    padding: 0 35px;
}

.ScardDetails p {
    font-size: 14px;
    font-weight: 400;
    color: #676C7B;
    overflow-wrap: anywhere;
}

.Completed {
    color: #0B9444 !important;
}

.MuiPaper-root:before {
    display: none !important;
}


/* ====== Accordion 3 Step End ======*/

.SessionStatus {
    font-size: 14px;
    color: #676C7B;
}

.TextField .MuiOutlinedInput-notchedOutline legend {
    display: none;
}

.MuiFormHelperText-root {
    margin: 4px 0 !important;
    font-size: 12px !important;
}

.priceFiled {
    padding-top: 15px;
    position: relative;
}

.priceFiled .priceICon {
    color: #676C7B;
    font-size: 18px;
    position: absolute;
    top: 60px;
    left: 10px;
}

.priceFiled .MuiInputBase-input {
    text-indent: 20px;
}

.ButtonSaveCont {
    position: fixed;
    top: 7px;
    z-index: 999;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.ButtonSave {
    display: flex;
    width: 74.5%;
    justify-content: end;
}

.ButtonSave .ButtonContainer {
    margin: 0 4px;
}

.ItemCount {
    margin: 0 !important;
    color: #676C7B;
    font-size: 14px;
    font-weight: 400;
}


/* ====== Farhan css ======*/

/* ====== user details css ======*/


.UserStatus {
    display: flex;
    background: #FCFCFC;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: #E4E8EC 1px solid;
    border-left: #E4E8EC 1px solid;
    border-right: #E4E8EC 1px solid;
}

.UserStatusCol {
    flex: 1;
    border-right: #E4E8EC 1px solid;
    padding: 10px;
    text-align: center;

}

.UserStatusCol:last-child {
    border: none;

}

.UplaodPhotoModal .FieldLabel {
    display: none;
}

.UplaodPhotoModal .FileUplaoder {
    margin-top: 27px;
}

/* ====== user details css  end======*/

.UserDetails li {
    background: #FFFFFF;
    padding: 16px;
    border: #E4E8EC 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    border-radius: 10px;
}

.UserDetails li .UserDetailsleftcol {
    display: flex;
    align-items: center;
}

.UserDetailsrIcon {
    margin-right: 24px;
}

.UserDetailsDetail {

}

.UserDetailsDetail h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.EditPhoto {
    text-align: center;
}


.UserDetails li .UserDetailsleftcol .UserDetailsrIcon span {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    background: #E4E8EC;
    color: #676C7B;
    padding: 4px;
    margin-right: 12px;
}



.UserDetails li .UserDetailsleftcol .UserDetailsDetail h4 {
    margin-bottom: 2px;
    font-size: 16px;
    color: #0B132B;
    font-weight: 600;
    margin-top: 0;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail p {
    margin: 0;
    font-size: 14px;
    color: #676C7B;
    font-weight: 400;
    word-break: break-all;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail a {
    margin: 0;
    font-size: 14px;
    color: #676C7B;
    font-weight: 400;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail p svg {
    font-size: 8px;
}

.UserDetails li .UserDetailsAction svg {
    color: #676C7B;
}

.UserDetails li .UserDetailsAction .lstbtn {
    background: #fff;
    border: #e1e1e7 1px solid;
    border-radius: 10px;
    color: #004FE0;
    font-weight: 600;
    font-size: 14px;
}

.UserDetails li .UserDetailsAction .lstbtn svg {
    color: #004FE0;
    font-size: 20px;
    margin-right: 8px;
}

.PaymentDetails {

    background-color: #2313b70f;
    padding: 16px;
    border-radius: 12px;
    border: #084f820f 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}


.OwnerPortalPaymentDetails {

    background-color: #f2f7f30f;
    padding: 16px;
    border-radius: 12px;
    border: #084f820f 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}


.BigIcon {
    font-size: 12px;
}


/* ====== Landing Page======*/
.Landing {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.Landing a {
    border: #e9e9e9 1px solid;
    padding: 60px;
    border-radius: 28px;
    margin: 15px;
    display: inline-block;
    background: #fff;
    text-decoration: none;
    font-size: 20px;
    text-transform: initial;
}

.AccrodionThreeStep {
    margin-bottom: 20px;
}

.AccrodionThreeStep:last-child {
    margin-bottom: 0px;
}

.UplaodPhotoModal .HelperText {
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    padding-top: 20px;
}

.divider {
    width: 100%;
    height: 1px;
    display: inline-block;
    border-bottom: 1px solid rgb(238, 237, 237);

}

.UpdateUseravatar {
    
}

.UplaodPhotoModal {
    flex: 1;
}

.RedioButton .MuiFormControlLabel-root {}

.RedioButton .MuiFormGroup-root {
    display: flex;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
}

.addText {
    font-size: 14px;
    color: #004FE0;
    cursor: pointer;
}

.emptySettionMessage {
    color: #A4A7B0;
    font-size: 14px;
    text-align: center;
}

.checkBox {
    margin-top: 10px;
    width: auto;
    position: relative;
    display: inline-block;
}


.checkBox .MuiTypography-root {
    font-size: 14px;
    color: #0B132B;
    font-family: 'Poppins', sans-serif !important;

}


.StapperBody .MuiTooltip-tooltip {
    left: 0 !important;
    top: 0 !important;
    position: fixed !important;
}

.tabletdvrf {
    display: flex;
    align-items: center;
}

.VerifyChip {
    background: #E1F2E8;
    color: #0B9444;
    font-size: 14px;
    padding: 3px 5px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    margin-left: 10px;
}

.VerifyChip svg {
    color: #0B9444;
    font-size: 18px;
    margin-right: 4px;

}

.PendingChip {
    background: #FFECE0;
    color: #FF6200;
    font-size: 14px;
    padding: 3px 5px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    margin-left: 10px;
}

.PendingChip svg {
    color: #FF6200;
    font-size: 18px;
    margin-right: 4px;

}

.cellwithchip {
    display: flex;
    align-items: center;
}

.actionDoropdown .MuiButtonBase-root {
    border: #E4E8EC 1px solid;
    height: 48px;
    padding: 0 15px;
    text-transform: initial !important;
    font-weight: 600;
    border-radius: 8px !important;
}

.MuiOutlinedInput-notchedOutline {
    border: #E4E8EC 1px solid !important;
}

.MarkCompleted {
    display: flex;
    align-items: center;
    margin-top: 15px;
    border-top: #e9e9e9 1px solid;
    padding-top: 10px;
    padding-bottom: 5px;
    cursor: pointer;
}


.MarkCompleted span {
    color: #004FE0;
    font-size: 14px;
    font-weight: 600;
}

.MarkCompleted svg {
    display: flex;
    color: #E4E8EC;
    margin-right: 8px;
    font-size: 30px;
}

.LargeText .TextField .MuiInputBase-root {
    width: 100%;
    height: 70px;
    font-size: 34px;
    font-weight: 700;

}

.LargeText .FieldLabel {
    font-size: 20px;
    color: #0B132B;
    font-weight: 500;
    margin-bottom: 10px;
}

.AvailaleContainer {
    position: relative;
}

.AvailableButton {
    color: #004FE0;
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 58px;
    cursor: pointer;
    font-weight: 600;
}

.AvailVerify {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
}

.AvailVerify svg {
    font-size: 19px;
    margin-right: 4px;

}

.AvailVerify.Yes {
    color: #0B9444;
}

.AvailVerify.No {
    color: #BA1A1A;
}

.LoadingBar {
    position: absolute;
    right: 20px;
    top: -10px;
}

.AvailableButton.IfLoading {
    color: #676C7B;


}

.ActionButton .MuiButtonBase-root {
    border: #E4E8EC 1px solid;
    height: 48px;
    border-radius: 8px;
    padding: 0 20px;
    text-transform: initial !important;
    color: #004FE0;
    font-weight: 600;
}

.BalanceCard .BalanceCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BalanceCardHeader h1 {
    font-size: 20px;
    color: #0B132B;
    margin: 0;
}

.BalanceCard .BalanceCardBody h2 {
    font-size: 48px;
    color: #0B132B;
    margin: 0;
    font-weight: 600;
}

.BalanceCard .BalanceCardBody p {
    font-size: 16px;
    color: #676C7B;
    margin: 0;
}

.RecentAnnouncementCard {
    background: #fff;
    box-shadow: 0px 0px 6px #00000017;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.RecentAnnouncementCard h1 {
    border-radius: 10px;
    font-size: 20px;
    color: #0B132B;
    margin: 0;
}

.RecentAnnouncementCard h5 {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #676C7B;
}

.RecentAnnouncementCard p {
    color: #676C7B;
    font-size: 16px;
}

.RecentAnnouncementimg {
    display: flex;
}

.RecentAnnouncementimg .box {
    background: #FFECE0;
    padding: 17px;
    display: flex;
    border-radius: 10px;
    margin-right: 24px;
    color: #FF6200;
}

.RecentAnnouncementimg {
    display: flex;
    align-items: center;
}

.verifiedIcon {
    margin-left: 5px;
}

.PassworedDots svg {
    font-size: 10px;
}


/* ==== Side Bar ==== */

.SidebarMenu {
    position: absolute;
    z-index: 105;
    bottom: 0;
    left: 0;
    max-width: 280px;
    width: 100%;
    top: 66px;
    background: #fff;
    box-shadow: 0px 1px 4px #00000017;
}

.SidebarMenu .MuiList-root {
    position: fixed;
    height: 100%;
    background: #fff;
    width: 270px;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root {
    padding: 0 !important;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root a {
    padding: 14px 28px;
    display: flex !important;
    align-items: center;
    width: 100%;
}


.SidebarMenu .MuiList-root .MuiButtonBase-root a {
    display: inline-block;
    text-decoration: none;
    color: #0B132B;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root .MuiSvgIcon-root {
    color: #0B132B;
}

.SidebarMenu .divider {
    width: 278px;
    height: 1px;
    display: inline-block;
    border-bottom: 1px solid rgb(238, 237, 237);

}



.BackButton svg {
    font-size: 18px;
}

/* ==== Side Bar End ==== */

.ResponsiveMenu {
    display: none;
}

.OrderTotalSection {
    background: #EBF1FD;
    border-radius: 10px;
    padding: 16px;
    text-align: center;
}


.OrderTotalSection p {
    color: #676C7B;
    font-size: 12px;
    margin: 0;
}

.OrderTotalSection h2 {
    color: #0B132B;
    font-size: 24px;
    margin: 0;
}

.OrderTotalSection h3 {
    color: #676C7B;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.OrderTotalSection h1 {
    color: #0B132B;
    font-size: 48px;
    margin: 0;
}

.Address span {
    margin-top: 15px;
    display: block;
}

.TableContainer .MuiTableRow-root.MuiTableRow-hover {
    background-color: transparent !important;
}


.ModalForm .TextField .MuiInputBase-root .MuiInputBase-input {
    position: relative;
    top: 0px;
    height: 8px;
}

.FontMedium {
    font-weight: 500 !important;
}

.TableInfo {
    font-weight: 600 !important;
    font-size: 14px !important;
}

.TableInfo a{
    color: #004FE0;
    text-decoration: none;
}

.TableInfo a:hover{
    text-decoration: underline;
}

.TableLabel {
    color: #676C7B !important;
    font-size: 14px !important;
}

.oddEvenTable .MuiTableCell-root {
    border: none !important;
}

.Stickyheader .HeaderContainer {
    position: sticky !important;
}


.formContainer {
    margin-top: 24px;
}

.MuiFormLabel-root {
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
}

.PeronalInfocard {
    display: flex;
    align-items: center;
}

.PeronalInfocardDetail {
    display: flex;
}


.PeronalInfocard .PeronalInfocardDetail h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.PeronalInfocard .PeronalInfocardDetail p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #676C7B;
}

.PeronalInfocard .UserIcon {
    margin-right: 15px;
}

.PeronalInfocard .PeronalInfocardDetail .MuiChip-root {
    margin-left: 10px;
    border-radius: 4px;
    height: 30px;
}

.FeeChip .MuiChip-root.MuiChip-filled {
    background-color: #E1F2E8;
    color: #0B9444;
    border-radius: 4px;
    margin-right: 8px;
}

.FeeChip a {
    color: #004FE0;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
}

.infoBox {
    color: #0B132B;
    font-size: 14px;
    font-family: 'Poppins', sans-serif !important;
}

.infoBox a {
    color: #004FE0;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif !important;
}

.Readmore {
    color: #004FE0 !important;
    text-decoration: none;
    cursor: pointer;

}

.PricingCell {
    display: none;
}

.DeleteTrack {
    background: #fff;
    padding: 24px;
    box-shadow: 0px 0px 6px #00000017;
    border-radius: 12px;
}

.lrTable{
    margin-top: 32px;
}

.lrTable .Tablechip {
    min-width: auto;
    min-height: 27px;
    display: flex;
    align-items: center;
}

.lrTable .Tablechip svg{
    width: 18px;
}

.lrTable .chipContainer {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.MarkCheck {
    padding-top: 10px;
}

.StatusMessage svg {
    color: #BA1A1A;
    margin-right: 16px;
}

.DeleteTrack h3 {
    color: #0B132B;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.DeleteTrack p {
    color: #676C7B;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.DeleteTrack .ButtonContainer {
    margin-top: 16px;
}

.emptyIcon {
    display: flex;
    justify-content: space-between;
}

.emptyIcon svg {
    position: absolute;
    right: 8px;
    font-size: 22px;
    color: #BA1A1A;
}

.TableContainer .MuiTable-root .MuiTableSortLabel-root {
    cursor: auto !important;
}

.tableLableTooltip {
    display: flex;
    color: #676C7B;
}

.tableLableTooltip svg {
    margin-right: 5px;
    position: relative;
    top: 3px;
}

.MuiList-root.MuiList-padding.MuiMenu-list .MuiButtonBase-root.MuiMenuItem-root {
    text-transform: initial !important;
}

.TableColDescription .MuiTableCell-root {
    vertical-align: baseline;
}

.TableTitle{
    color: #0B132B;
    font-size: 34px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0px;
}

.TotalMonth {
    position: relative;
}

.TotalMonth .MonthLabel {
    position: absolute;
    right: 37px;
    top: 26px;
    font-size: 16px;
    color: #A4A7B0;
    font-weight: 400;
}

.TotalMonth input[type=number]::-webkit-inner-spin-button,
.TotalMonth input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}


/* ================================ PaymentMethodBox button css start ====================================== */
.PaymentMethodBox .MuiTabs-indicator {
    display: none;
}

.PaymentMethodBox .PaymentMethodBoxTabBody .MuiBox-root {
    padding: 0;
}

/* ------------- add new member phone field ---------- */
.addmemberPhoneFeild .PhoneFieldManContainer .react-tel-input .form-control{
    border: 1px solid #E4E8EC !important;
    padding: 18.5px 14px 18.5px 75px !important;
    min-height: 46px !important;
    font-family: 'Poppins', sans-serif !important;
}

.addmemberPhoneFeild .PhoneFieldManContainer .react-tel-input{
    margin-top: 8px;
}

.even-row{
    background-color: red !important;
}

.PaymentMethodBox .UnSelectedRedio {
    color: #ffffff;
    border-radius: 100px;
    /* border: #A4A7B0 2px solid; */
    font-size: 12px;
}

.PaymentMethodBox .MuiTab-root.Mui-selected .UnSelectedRedio {
    display: none;
}

.PaymentMethodBox .MuiTab-root .SelectedRedio {
    display: none;
}

.PaymentMethodBox .MuiTab-root.Mui-selected .SelectedRedio {
    color: #004FE0;
    display: block;
    padding: 0;
    /* border: #004FE0 1px solid; */
    border-radius: 100px;
    font-size: 18px;
}

.PaymentMethodBox .RedioIcon {
    position: relative;
    top: -11px;
    right: -11px;
}

.PaymentMethodBox .MuiTabs-root {
    display: flex;
    overflow: visible !important;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
}

.PaymentMethodBox .MuiTabs-scroller {
    flex: 1 1 auto;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow: visible !important;
}

.PaymentMethodBox .MuiTab-root:last-child {
    margin-right: 0 !important;
}

.PaymentMethodBox .MuiTab-wrapper {
    flex-direction: initial !important;
    justify-content: left !important;
}

.PaymentMethodBoxTabBody {
    padding-top: 32px;
}

@media (max-width: 480px) {
    .PaymentMethodBox .MuiTab-root {
        max-width: 114px !important;
        padding-left: 7px;
    }

    .PaymentMethodBox .RedioContents {
        flex-direction: row-reverse;
    }
}

/* ================================ PaymentMethodBoxTabBody button css end ======================================== */

/* =============================== cash payment date field start ==================================== */

.PDrawerLabel {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pendingChip {
    padding: 5px;
    border-radius: 8px;
    color: #FFAA00;
    background-color: #FFF5E0;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.MuiFormControl-root {
    width: 100%;
}

.Datefield .MuiInputLabel-outlined {
    top: -3px;
}

@media (max-width: 768px) {
    .Datefield {
        width: 100%;
    }
}

.CrLst{
    padding-bottom: 15px;
}

.DateTime .MuiInputBase-root {
    position: relative;
}

.DateTime .MuiInputBase-root::after {
    content: "";
    position: absolute;
    background-image: url(../imgs/dateIcon.svg);
    width: 40px;
    height: 40px;
    right: -5px;
    top: 12px;
    background-repeat: no-repeat;
}

/* =============================== cash payment date field end ==================================== */


/* == SUMMARY == */

.SummarySection{
    margin-bottom: 32px;
}

.SummaryCar h1 {
    font-size: 20px;
    color: #0B132B;
    font-weight: 600;
    margin-top: 0;
}

.SummaryCar .SummaryTable {
    background: #fff;
    border-radius: 8px;
    border: #E4E8EC 1px solid;
}

.SummaryCar .SummaryTable .SummaryTableRwo {
    display: flex;
    justify-content: space-between;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell{
    padding: 10px;
    font-size: 14px;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell.LightText{
    font-weight: 500;
    color: #676C7B;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell.BoldText{
    font-weight: 600;
    color: #0B132B;
}

.SummaryCar .SummaryTable .SummaryTableRwo.DarkRwo{
    background: #F8F8F9;
}

.TableImgCell {
    display: flex;
    align-items: center;
}

.TableImgCell img{
   width: 40px;
}

.TableImgCell p{
   margin: 0;
   color: #0B132B;
   font-weight: 500;
   font-size: 14px;
   margin-left: 10px;
}

.SumamryTitle{
    font-size: 14px;
    color: #676C7B;
    font-weight: 400;
    margin-bottom: 4px;
}

.PMI{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    padding:4px;
}

.PMI img{
    display: inline-block;
    width: 18px;
}
.CrLst{
    padding-bottom: 15px;
}

.PupColor{
    background: #6D1FD4;
}

.BlueColor{
    background: #004FE0;
}

/* == SUMMARY == */


.MuiSnackbar-root .MuiAlert-message {
    color: #fff !important;
}


















/* ==== RESPONSIVE ==== */

@media (max-width: 1500px) {
    .app-body {
        width: 79%;
        height: 400px;
        margin-left: 21%;
        padding: 20px;
    }

    .ButtonContainer {
        min-width: 160px;
    }

    .textDate {
        min-width: 150px;
    }

    .CellCont {
        min-width: 150px;
    }
}

@media (max-width: 1300px) {

    .DesktopView {
        display: none;
    }

    .ResponsiveMenu {
        display: block;
    }

    .app-body {
        width: auto;
        height: 400px;
        margin-left: 0;
        padding: 20px;
    }

    .Header .Branding img {
        height: 25px;
    }

    .SidebarMenu {
        width: 280px;
        max-width: initial;
    }

    .Header {
        height: 56px;
        justify-content: space-between;
        align-items: center;
    }

    .MuiTableContainer-root {
        width: 800px;
        overflow-x: auto !important;
    }



    .SideBarDrawer .MuiDrawer-paperAnchorLeft {
        width: 250px;
    }

    .SidebarMenu {
        width: 250px;
        max-width: initial;
    }

    .SideBarDrawer .MuiDrawer-paper {
        overflow-y: initial;

    }
}

@media (max-width: 768px) {

    .UserDetails li {
        display: block;
        margin-top: 20px;
        border-radius: 10px;
    }

    .UserDetails li .ButtonContainer .MuiButtonBase-root {
        width: 100%;
        margin-top: 15px;
    }

    .accTitleSection .MuiTypography-root {
        width: fit-content;
    }

    .UpdateUseravatar {
        display: block;
    }

    .EditPhoto {
        text-align: center;
        display: block;
    }

    .EditPhoto .FieldLabel {
        display: inline-block;
    }

    .EditPhotoAvatar {
        display: flex;
        justify-content: center;
    }

    .ScardDetails {
        padding: 0 0;
    }

    .accTitleSection {
        display: block;
    }

    .accTitleSectionIcon {
        display: block;
    }

    .SesstionStatus {
        margin-top: 10px;
        position: absolute;
        top: 10px;
        right: 50px;
    }

    .accTitleSectionIcon p {
        margin-right: 5px;
    }

    .ScardDetails p {
        margin-top: 0;
    }



    .AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        top: 20px;
        right: 19px;
        left: initial !important;
    }

    .SessionListing li .Sessionleftcol {
        display: block;
    }

    .SessionListing li {
        display: block;

    }

    .SessionListing li .SessionListingAction .lstbtn {
        width: 100%;
        margin-top: 10px;
    }

    .emptyIcon svg {
        position: relative;
        right: -6px;
    }



}

@media (max-width: 520px) {
.accTitleSectionIcon svg {
    color: transparent;
    display: block;
}
}