.AlertMessageTwo {
    background: #fff;
    padding: 16px;
    border: #BA1A1A 1px solid;
    border-radius: 8px;
    display: flex;
    margin-bottom: 20px;
}

.AlertMessageTwo svg{
    color: #BA1A1A;
    margin-right: 16px;
}

.AlertMessageTwo p{
    margin: 0 !important;
    color: #1B1B1F;
    font-size: 14px;
    font-family: 'Poppins', sans-serif !important;
}

.AlertMessageTwo p a{
    color: #004FE0;
    font-size: 14px;
    font-family: 'Poppins', sans-serif !important;
    cursor: pointer;
    text-decoration: underline;
}