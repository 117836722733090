

.BlueAlertMessage {
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    background:#004FE0;
    height: 72px;
    padding: 16px;
    z-index: 99990;
}

.BlMessageContainer{
    max-width: 1064px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.BlMessageContainer svg{
    color: #FFFFFF;
    font-size: 30px;
    margin-right: 22px;
}
.BlMessageContainer h2{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
}

.BlMessageContainer h2 span{
    color: #FFFFFF;
    font-weight: 300;
    font-size: 24px;
}

.BlMessageContainer p{
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    opacity: 0.64;
}

.BlueAlertMessage .BlueAlertMessageIconButton{
    color: #FFFFFF;
}

.AlrtMessageContainer .AlrtButton {
    margin-left: 18px;
    color: #fff;
    text-transform: initial;
    font-size: 14px;
    min-width: fit-content;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: #0D3FB3;
}

.AlrtMessageContainer .AlrtButton:hover{
    background-color: #0D3FB3;
}

.BlMessageLeftContainer{
    display: flex;
    align-items: center;
}

@media (max-width: 660px) {
    .BlueAlertMessage {
        height: auto;
        z-index: 1;

    }

    .BlueAlertMessage {
        position: relative;
    }

    .BlMessageContainer {
        display: block;
    }

    .AlrtMessageContainer .AlrtButton {
        margin-left: 48px;
        margin-top: 12px;
        width: 87%;
    }

    .BlueAlertMessage .BlueAlertMessageIconButton {
        color: #FFFFFF;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .BlMessageContainer h2 {
        font-size: 17px;
    }

    .BlMessageContainer p {
        font-size: 14px;

    }

    .BlueAlertMessage .AlrtMessageContainer .AlrtButton {
        margin-left: 1%;
        margin-top: 12px;
        width: 98%;
    }
}
