.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

.FixedSizeTextArea .TextArea textarea {
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  font-size: 17px;
  min-height: 100px;
  padding: 2%;
  width: 100%;
  max-height: 100px;
  min-width: 100%;
  max-width: 100%;
}

.viewPhoto {
  font-size: 14px;
  color: #004fe0;
  margin-top: 4px;
  font-weight: 500;
  cursor: pointer;
}

.cursorPoint {
  cursor: pointer;
}

.ExportStudentsCheckBox .CheckBox {
  padding-bottom: 12px;
}
