.EmptyCard {
    background: #fff;
    box-shadow: 0px 1px 4px #00000017;
    border: 1px solid #F2F0F4;
    border-radius: 12px;
    padding: 56px;
    text-align: center;


}

.EmptyCard h1{
    font-size:24px;
    color: #1B1B1F;
}

.EmptyCard p{
    font-size:16px;
    color: #5E5E62;
}

.EmptyCardIcon{
    width: 200px;
}

.EmptyCardButton{
    display: flex;
    justify-content: center;
}

.EmptyCardContainer .TableHeader .TableTitle {
    color: #0B132B;
    font-size: 34px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0px;
    margin-bottom: 20px;
}