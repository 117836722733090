
.UplaodLibModalContainer .UplaodLibModalBody {
  width: 800px;
  display: flex;
  height: 100%;
}

.UplaodLibModalContainer .MuiDialogContent-root{
  overflow: initial !important;
  padding: 0;
}
.UplaodLibModalContainer .MuiPaper-root.MuiPaper-elevation{
  overflow: initial !important;
  max-width: initial !important;
}

.UplaodLibModalContainer .MuiDialog-paper {
  border-radius: 4px !important;
  padding: 0px;
}








@media (max-width: 768px) {



}


