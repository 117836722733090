.SwitchAccordion .MuiAccordion-root.MuiAccordion-rounded{
    background: #EBF1FD;
    box-shadow: none;
    margin-bottom: 20px;
    border-radius: 12px;
}

.SwitchAccordion .MuiAccordion-root.MuiAccordion-rounded.Mui-expanded{
    margin-bottom: 20px;
}

.SwitchAccordion .AccordionTitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.SwitchAccordion .MuiAccordionSummary-content .MuiTypography-root{
    font-weight: 600;
    display: flex;
    align-items: center;
}

.SwitchAccordion .TextField .MuiInputBase-root {
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 12px;
    padding-top: 8px;
}

.SwitchAccordion .TextField .MuiInputBase-root .MuiInputBase-input {
    position: relative;
    top: -2px;
}

.SwitchAccordion .MuiAccordionSummary-content.Mui-expanded{
    margin-bottom: 0 !important;
}


