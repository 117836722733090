.AlrtMessage {
    min-height: 34px;
    display: flex;
    align-items: center;
    padding: 20px;
    position: relative;
    z-index: 999;
}

.AlrtMessageContainer {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.AlrtMessageContainer p {
    color: #fff;
    margin: 0;
}

.AlrtMessageContainer svg {
    color: #fff;
    margin: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
}

.AlrtMessageContainer .AlrtButton {
    margin-left: 18px;
    color: #fff;
    text-transform: initial;
    font-size: 14px;
    min-width: fit-content;
    padding: 10px 16px;
    border-radius: 8px;
}

.AlrtMessageInfo {
    display: flex;
    align-items: center;
}

.AlrtMessageCloseIcon{
    position: absolute;
    right: 20px;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .AlrtMessageContainer {
        max-width: initial !important;
    }
}

@media (max-width: 660px) {
    .AlrtMessageContainer {
        max-width: initial !important;
    }

    .AlrtMessageContainer p {
        max-width: fit-content;
    }

    .AlrtMessageContainer {
        display: block;
        margin: 0 auto;
    }

    .AlrtMessageContainer .AlrtButton {
        min-width: 100%;
        margin-left: 0;
        margin-top: 15px;
    }

    .AlrtMessageCloseIcon{
        right: 10px;
        top: 11px;
    }
}

