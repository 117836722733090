
.PriceTextField legend{
    display: none;
}

.PriceTextField .MuiOutlinedInput-root {
    position: relative;
    border-radius: 12px;
    background: #fff;
    height: 48px;
}

.PriceTextField .MuiOutlinedInput-notchedOutline {
    border: #E4E8EC 1px solid;
    height: 48px;
    top: 0 !important;
}

.PriceTextField .PriceTextFieldLabel {
    font-size: 14px !important;
    color: #676C7B;
    margin-bottom: 4px;
    font-weight: 400;
    display: flex;
    height: 26px;
    font-family: 'Poppins', sans-serif !important;
    align-items: center;
}

.PriceTextField .PriceTextFieldLabel .MuiSvgIcon-root {
    font-size: 20px;
    margin-top: 5px;
    margin-left: 4px;
}

.PriceTextField .MuiInputBase-input {
    padding-left: 2px;
}

/* Chrome, Safari, Edge, Opera */
.PriceTextField  input::-webkit-outer-spin-button,
.PriceTextField  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.PriceTextField  input[type=number] {
  -moz-appearance: textfield;
}