.SelectFieldMulticolor .MuiInput-underline:before{
    display: none;
}

.SelectFieldMulticolor .MuiInput-underline:after{
    display: none;
}

.SelectFieldMulticolor .SelectFieldMulticolorBody {
    border: #D2D3D8 1px solid;
    border-radius: 4px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
}
.SelectFieldMulticolor .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
}

.SelectFieldMulticolor .MuiSelect-root.MuiSelect-select {
    font-size: 14px;
    font-weight: 500;
}