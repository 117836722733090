
.CheckBoxContianer{
    display: flex;
}

.CheckBoxContianer .MuiCheckbox-root{
    padding: 0 9px;
}

.CheckBoxContianer .MuiCheckbox-colorPrimary.Mui-checked {
    color: #0450E1;
}

.CheckBoxContianer .MuiFormControlLabel-label {
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px;
    color: #0B132B;
}