.ButtonContainer .MuiButtonBase-root {
    border-radius: 12px !important;
    padding: 0 20px;
    text-transform: initial !important;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ButtonContainer .Mui-disabled {
    background: #E1E2E5 !important;
    color: #A7AAB3 !important;
}

.ButtonContainer .MuiButtonBase-root svg{
    margin-right: 10px;
}

.ButtonIconAfter {
    display: flex;
}

.ButtonIconAfter svg{
    margin: 0 !important;
}

