
.SummarySection{
    margin-bottom: 32px;
}

.SummaryCar h1 {
    font-size: 20px;
    color: #0B132B;
    font-weight: 600;
    margin-top: 0;
}

.SummaryCar .SummaryTable {
    background: #fff;
    border-radius: 8px;
    border: #E4E8EC 1px solid;
}

.SummaryCar .SummaryTable .SummaryTableRwo {
    display: flex;
    justify-content: space-between;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell{
    padding: 10px;
    font-size: 14px;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell.LightText{
    font-weight: 500;
    color: #676C7B;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell.BoldText{
    font-weight: 600;
    color: #0B132B;
}

.SummaryCar .SummaryTable .SummaryTableRwo.DarkRwo{
    background: #F8F8F9;
}

.TableImgCell {
    display: flex;
    align-items: center;
}

.TableImgCell img{
   width: 40px;
}

.TableImgCell p{
   margin: 0;
   color: #0B132B;
   font-weight: 500;
   font-size: 14px;
   margin-left: 10px;
}

.SumamryTitle{
    font-size: 14px;
    color: #676C7B;
    font-weight: 400;
    margin-bottom: 4px;
}

.PMI{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    padding:4px;
}

.PMI img{
    display: inline-block;
    width: 18px;
}

.PupColor{
    background: #6D1FD4;
}

.BlueColor{
    background: #004FE0;
}

/* == SUMMARY == */

.DrawerLavelWithStatusChip{
    display: flex;
    align-items: center;
}

.DrawerLavelWithStatusChip .StatusChip{
    margin-left: 8px;
}