.RedioBox .MuiTabs-indicator{
    display: none;
}

.RedioBox .MuiTab-root{
    border: #E4E8EC 1px solid;
    border-radius: 8px;
    margin-right: 16px;
    flex: 1;
    width: 100%;
    padding: 16px;
    max-width: initial !important;
}

.RedioBox .RedioContents{
    display: flex;
}

.RedioBox .RedioContents .RedioLebel{
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
}

.RedioBox .RedioContents .RedioLebel h3{
   margin: 0;
   font-size: 14px;
   text-transform: initial;
   color: #0B132B;
   font-weight: 500;
}

.RedioBox .MuiTab-root.Mui-selected h3{
    color: #004FE0;
}

.RedioBox .RedioContents .RedioLebel p {
    margin: 0;
    font-size: 12px;
    text-transform: initial;
    font-weight: 500;
    width: 100%;
    text-align: left;
    color: #676C7B;
    letter-spacing: 0px;
}

.RedioBox .MuiTab-root.Mui-selected{
    border: #004FE0 1px solid;
    border-radius: 8px;
    background: #004FE014;
}

.RedioBox  .UnSelectedRedio{
    color: #ffffff;
    padding: 1px;
    border-radius: 100px;
    border: #A4A7B0 2px solid;
    font-size: 20px;
}

.RedioBox .MuiTab-root.Mui-selected .UnSelectedRedio{
    display: none;
}

.RedioBox .MuiTab-root .SelectedRedio{
    display: none;
}

.RedioBox .MuiTab-root.Mui-selected .SelectedRedio{
    color: #004FE0;
    display: block;
    padding: 1px;
    border: #004FE0 2px solid;
    border-radius: 100px;
    font-size: 20px;
}

.RedioBox .RedioIcon{
position: relative;
top: 3px;
}

.RedioBox .MuiTabs-root {
    display: flex;
    overflow: visible !important;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
}

.RedioBox .MuiTabs-scroller {
    flex: 1 1 auto;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow: visible !important;
}

.RedioBox .MuiTab-root:last-child{
    margin-right: 0 !important;
}

.RedioBox .MuiTab-wrapper {
    flex-direction: initial !important;
    justify-content: left !important;
}

.RedioBoxTabBody{
    padding-top: 32px;
}

.RedioBox .MuiTab-textColorInherit {
    opacity: 1;
}

.FullWidth .MuiTabs-flexContainer {
    display: block;
}

.FullWidth .RedioBox .MuiTabs-root {
    display: block;
    overflow: visible !important;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
}

.FullWidth .RedioBox .MuiTabs-scroller {
    white-space: initial !important;
}

.FullWidth .MuiButtonBase-root.MuiTab-root{
 margin-bottom: 12px;
}
