.MediaLibraryContainer{
    display: flex;
    justify-content: space-between;
}

.MediaLibraryTitle {
    font-family: Poppins, sans-serif !important;
    font-weight: 300;
    font-size: 28px;
    color: #5a5a5a;
    margin-left: 8px;
}

.MediaLibraryHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: #e9e9e9 1px solid;
    padding-bottom: 24px;
}

.MediaLibraryRihtCol {
    display: flex;
    align-items: center;
}

.MediaLibraryRihtCol .MediaLibraryHeaderButton {
    background: #004FE0;
    color: #fff;
    border-radius: 12px !important;
    margin-left: 10px;
    text-transform: initial !important;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    padding: 0 15px;
}

.MediaLibraryRihtCol .MediaLibraryHeaderButton:hover {
    background: #004FE0;
    color: #fff;
}

.MediaLibraryFilter {
    margin-top: 20px;
}

.MediaLibraryFilter .MuiInputBase-root.MuiOutlinedInput-root {
    margin-right: 15px;
    background: #fff;
    width: 200px;
    ;
}

.MediaLibraryImgGrid{
    margin-top: 15px;
    margin-left: -5px;
}

.ImageGrid .MuiButtonBase-root.MuiTab-root {
    width: 150px;
    max-width: initial !important;
    min-width: initial !important;
    padding: 6px;
}

.ImageGridItemContainer{
    position: relative;
    height: 120px;
    max-width: initial !important;
    min-width: initial !important;
    overflow: hidden;
}

.ImageGridItemContainer svg{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    display: none;
}

.ImageGrid .MuiButtonBase-root.MuiTab-root .ImageGridItem {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.ImageGrid .MuiTabs-indicator {
    display: none;
}

.ImageGrid .MuiTabs-root .Mui-selected svg{
    display: block;
}

.ImageGrid  .ImageGridItemContainer .ImageAlfa {
    background: #000;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    top: 0;
    border-radius: 8px;
}

.ImageGrid .MuiTabs-root .Mui-selected .ImageAlfa {
   display: block;
}

.ImageGrid .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer {
    flex-wrap: wrap !important;
}


.MediaLibraryRightCold {
    width: 330px;
    height: 100%;
}
.mdLibSideBar {
    background: #fff;
    height: 100%;
    position: fixed;
    top: 0;
    padding: 10px;
    right: 0;
    width: 330px;
    top: 150px;
}
  
.FeatureImgContainer {
    height: 160px;
    background: #fff;
    margin-bottom: 20px;
}
  .FeatureImgContainer img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
  
  .upgradeAlertBox {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .FetureImgDetails li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .FetureImgDetails li h3 {
    font-size: 14px;
    color: #4d4d4d;
    font-weight: 600;
    font-family: "Poppins", sans-serif !important;
    margin: 0;
    margin-right: 5px;
  }
  
  .FetureImgDetails li p {
    font-size: 14px;
    color: #4d4d4d;
    font-weight: 400;
    font-family: "Poppins", sans-serif !important;
    margin: 0;
  }
  
  .DeleteImgButton {
    margin-top: 30px;
  }
  .DeleteImgButton .MuiButton-textSecondary {
    color: #b00020;
    border: #e8e9e9 1px solid;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif !important;
    padding: 7px 30px;
    width: 100%;
  }
  
  .DeleteImgButton .MuiButton-textSecondary svg {
    font-size: 21px;
    margin-right: 4px;
    position: relative;
    top: -2px;
  }
  
  .BootmInfodetailsContainer {
    position: absolute;
    bottom: 148px;
    width: 70%;
  }
  .BootmInfodetails {
    border-top: #e4e8ec 1px solid;
    border-bottom: #e4e8ec 1px solid;
    padding: 17px 0;
  }
  
  .BootmInfodetails li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .BootmInfodetails li p {
    margin: 0;
    color: #818e94;
    font-size: 12px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif !important;
  }
  
  .BootmInfodetails h4 {
    margin: 0;
    color: #4d4d4d;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Poppins", sans-serif !important;
  }
  .upgradeNowButton span {
    color: #2a82f6 !important;
  }
  
  .upgradeNowButton {
    padding: 0 !important;
    margin-top: 8px !important;
  }
  
  .upgradeNowButton:hover {
    background: none;
    text-decoration: underline !important;
    background-color: transparent !important;
  }
  
  .ProgressbarCol {
    margin-top: 13px;
  }
  
  .ProgressbarCol p {
    margin: 0;
    color: #818e94;
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins", sans-serif !important;
    margin-top: 12px;
  }

  .BootmInfodetailsFooter {
    position: fixed;
    bottom: 0;
    width: inherit;
    padding-right: 22px;
}

.MediaLibPanelInfoText{
  font-size: 14px;
  color: #676C7B;
  display: flex;
  align-items: center;
}

.MediaLibPanelInfoText svg{
  margin-right: 4px;
}
.MediaLibPanelInfoList{
  font-size: 16px;
  color: #0B132B;
  font-weight: 400;
  margin-top: 8px;

}

.MediaLibPanelInfoList a{
  font-size: 16px;
  color: #004FE0;
  font-weight: 400;
  text-decoration: none;
  margin-left: 4px;
}

.MediaLibPanelInfoList a svg{
 position: relative;
 top: 5px;
 font-size: 20px;
}

.ReplyIcon{
  rotate: 180deg;
  transform: rotateX(180deg);
  position: relative;
  top: 4px;
  font-size: 16px;
  margin-left: 4px;
}

.EditImageSection {
  display: flex;
}

.EditImageSection .EditImageCol{
  width: 224px;
  height: 126px;
  margin-right: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditImageSection .MeidaImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.EditImageSection .EditImageCol .alfa{
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.EditImageSection .EditImageCol .youtubeIcon{
  position: absolute;
  z-index: 2;
  width: 32px;
  height: 32px;
}




.MediaLibPanelContainer .MuiFormHelperText-root.Mui-error{
  position: absolute;
  top: 45px;
}

.MediaLibPanelContainer .TextField .MuiInputBase-root .MuiInputBase-input {
  position: relative;
  top: -3px;
  padding: 0 17px;
}

.MediaLibPanelContainer .FieldCount {
  right: 18px;
  bottom: 16px;
}



.MediaLibPanelContainer .custom-file-input::before {
  width: 100%;

}

.MediaLibPanelTextField .FieldCount {
  right: 15px;
  bottom: 15px;
}



@media (max-width: 768px) {
  .MediaLibPanelContainer .FieldCount {
    right: 5px;
    bottom: -22px;
}

.EditImageSection {
  display: block;
  margin-top: 10px;
}

.EditImageSection .EditImageCol {
  width: 100%;
  height: 166px;
  margin-right: 0;
}

.EditImageSection .ButtonContainer .MuiButtonBase-root {
  width: 100%;
  margin-top: 20px;
}

}