.VerticalTab {
    display: flex
}

.VerticalTab .MuiBox-root {
    padding: 0 !important;
}

.VerticalTab .TabListingContainer {
    max-width: 310px;
    padding-right: 48px;
}

.VerticalTab .TabTitle {
    max-width: 240px;
}

.VerticalTab .TabTitle h1 {
    font-size: 24px;
    color: #0B132B;
    margin-top: 0;
}

.VerticalTab .TabTitle p {
    margin-bottom: 38px;
}

.VerticalTab .TabTitle p a {
    font-size: 14px;
    color: #676C7B;
    margin-top: 0;
}

.VerticalTab .MuiAppBar-colorPrimary {
    background-color: transparent;
    box-shadow: none !important;
}

.VerticalTab .MuiTab-textColorInherit {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    opacity: 1 !important;
}




.VerticalTab .MuiTab-textColorInherit .MuiTab-wrapper {
    color: #0B132B;
    display: flex;
    flex-direction: initial !important;
    align-items: center;
    text-transform: initial !important;
    font-weight: 600;
    justify-content: left;
}

.VerticalTab .MuiTab-textColorInherit .MuiTab-wrapper .MuiSvgIcon-root {
    margin-bottom: 0 !important;
    margin-right: 12px;
}

.VerticalTab .MuiTabs-flexContainer {
    display: block;
}

.VerticalTab .MuiTabs-indicator {
    display: none;
}

.VerticalTab .MuiTab-textColorInherit.Mui-selected {
    background: #E5EBF7;
    border-radius: 8px;
}

.VerticalTab .MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper {
    color: #004FE0;
}

.VerticalTab .MuiTab-labelIcon {
    min-height: auto;
    padding-top: 9px;
}


.VerticalTabBody {
    flex: 1;
    padding: 24px 0;
}

.VerticalTab .MuiTabScrollButton-root {
    display: block !important;
}

.MobileViewTabs .MuiTab-wrapper {
    display: flex;
    flex-direction: initial !important;
    align-items: center;
    text-transform: initial !important;
    font-weight: 600;
    justify-content: left;
}

.MobileViewTabs .MuiTab-wrapper svg {
    margin-right: 4px;
    margin-bottom: 0 !important;
}

.MobileViewTabs .MuiTab-textColorPrimary.Mui-selected {
    background: #E5EBF7;
    border-radius: 8px;
}


.MobileViewTabs .MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper {
    color: #004FE0;
}

.MobileViewTabs .MuiTabScrollButton-root {
    display: flex !important;
}

.MobileViewTabs .MuiTabScrollButton-root.MuiTabScrollButton-horizontal {
    border-radius: 100px;
    width: 48px;
    height: 48px;
    border: #E4E8EC 1px solid;
    color: #004FE0;
    margin-right: 12px;
    margin-left: 12px;
}

.MobileViewTabs .MuiTabScrollButton-root.MuiTabScrollButton-horizontal svg {
    font-size: 30px !important;
}

.MobileViewTabs .MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled {
    opacity: 0 !important;
    display: none !important;
}

.MobileViewTabs .MuiTab-textColorPrimary.Mui-selected {
    color: #004FE0 !important;
}

.MobileViewTabs {
    display: none;
}

.MobileViewTabs .MuiTabs-root {
    align-items: center !important;
}

@media (max-width: 768px) {
    .VerticalTab .MuiTab-textColorInherit {
        width: auto;
        display: initial;
    }

    .VerticalTab {
        display: block;
    }

    .VerticalTab .TabListingContainer {
        max-width: initial;
        padding-right: 0;
    }

    .VerticalTabBody {
        flex: 1 1;
        padding: 10px 0;
    }

    .MobileViewTabs {
        display: block;
    }

    .DekstopViewTabs {
        display: none !important;
    }

    .VerticalTab .TabTitle {
        max-width: initial !important;
    }


}