.FieldLabelUpload {
  font-size: 16px !important;
  color: #0B132B;
  margin-bottom: 4px;
  font-weight: 600;
  display: flex;
  height: 26px;
  font-family: 'Poppins', sans-serif !important;
}

.FileUplaoderFieldContainer {
  position: relative;
}

.FileUplaoderFieldContainer .FileUplaoderField{
  position: relative;
}

.FileUplaoderFieldContainer .FileUplaoderField .DeleteIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #7a7979;
}

.FileUplaoderFieldContainer input {
  border: #E4E8EC 1px solid;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  color: #0B132B;
}

.FileUplaoderFieldContainer input::file-selector-button {
  font-weight: bold;
  color: #fff;
  border-radius: 8px;
  background: #004FE0;
  width: 144px;
  height: 44px;
  outline: none !important;
  border: #004FE0 1px solid;
  position: relative;
  margin-right: 15px;
  text-indent: 23px;
 
}

.FileUplaoderFieldContainer input::after {
  content: "";
  background-image: url('./file_upload.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 30px;
  top: 22px;

}

.FieldError .uploadfield{
  border: #d32f2f 1px solid !important;
}

.FieldError .HelperText {
  color: #d32f2f;
  font-size: 12px !important;
  margin-top: 0px;
}





 

  
  