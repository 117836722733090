
.Switch{
    display: flex;
    justify-content: space-between;
}

.Switch .SwitchLeft h1{
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 600;
    margin: 0;
}

.Switch .SwitchLeft h1 span{
    font-size: 12px;
    color: #6200EE;
    font-weight: 400;
    margin: 0;
    background: #ECE0FD;
    border-radius: 100px;
    padding: 2px 8px;
    display: inline-block;
}



.Switch .SwitchLeft p{
    font-size: 14px;
    color: #4d4d4d;
    font-weight: 400;
    margin: 0;
}

.Switch{
    display: flex;
    justify-content: space-between;
}

.BorderLine{
    height: 1px;
    width: 100%;
    background: #e3e3e3;
    margin-top: 15px;
    margin-bottom: 15px;
}

