.DialogBoxContainer .DialogTitle {
    margin: 0 !important;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: #0B132B !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
}

.MuiDialog-container .MuiDialog-paperScrollPaper {
    border-radius: 28px;
    padding: 30px;
    min-width: 552px;

}

.MuiDialog-container .MuiDialogContentText-root{
    color: #0B132B;
}

.MuiDialog-container .MuiDialog-paperScrollPaper strong {
    color: #0B132B;

}

.MuiDialog-container .MuiDialogContent-root {
    padding: 0 !important;
    font-family: 'Poppins', sans-serif;
    color: #0b132b;
    overflow-x: hidden;
}

.DialogFooterContainer {
    display: flex;
    justify-content: right;
    margin-top: 20px;
}

.DialogFooterContainer .MuiButtonBase-root {}

.DialogFooterContainer .MuiButtonBase-root {
    height: 38px;
    box-shadow: none;
    font-size: 14px;
}

.DialogFooterContainer .DialogbtLight {
    color: #0b132b;
    background: #fff;
    border: none !important;
    border-radius: 10px !important;
    text-transform: initial !important;
    padding: 10px 8px;
}

.DialogFooterContainer .DialogbtLight:hover {
    color: #676C7B;
    background: none;

}

.DialogFooterContainer .DialogbtnBlue {
    color: #004FE0;
    border-radius: 10px !important;
    text-transform: initial !important;
    padding: 10px 15px;
    margin-left: 10px;
}

.DialogFooterContainer .DialogbtnBlue:hover {
    background: none;
    ;
}



@media (max-width: 768px) {
    .MuiDialog-container .MuiDialog-paperScrollPaper {
        border-radius: 28px !important;
        padding: 30px !important;
        min-width: auto;

    }


}