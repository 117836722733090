.HrTabHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HrTabHeader .HrTabTitle{
    color: #0B132B;
    font-size: 34px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin: 0px;
}

.HrTabsFill .MuiTabs-root{
    background: #E4E8EC;
    border-radius: 100px;
    max-width:fit-content;
}

.HrTabsFill .MuiTabs-root .MuiTabs-indicator {
    background: #004FE0;
    height: 48px;
    border-radius: 100px;
}

.HrTabsFill .MuiTab-wrapper{
    text-transform: initial !important;
    font-size: 14px !important;
    color: #0B132B;
    font-weight: 600 !important;
    padding: 0 15px;
    margin: 0;
}

.HrTabsFill .Mui-selected .MuiTab-wrapper{
    color: #fff;
}



.HrTabsFill .HrTabsFillBody{
    padding-top: 20px;
}

.HrTabsFill .Table .TableHeader {
    margin-bottom: auto !important;
}

.HrTabsFill .MuiTabs-flexContainer {
    display: flex;
    position: relative;
    z-index: 2;
}

.HrTabsFillTabSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


