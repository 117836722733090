.SwitchButtonIOS .MuiSwitch-root {
    width: 72px;
    height: 50px;
}

.SwitchButtonIOS .MuiSwitch-root .MuiButtonBase-root.MuiSwitch-switchBase::after {
    content: "OFF";
    position: absolute;
    color: #767680;
    right: -12px;
    font-size: 10px;
    font-weight: 600;
    top: 10px;
    opacity: 1;
}

.SwitchButtonIOS .MuiSwitch-root .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked::after {
    content: "ON";
    position: absolute;
    color: #fff;
    right: 29px;
    font-size: 10px;
    font-weight: 600;
    top: 12px;
    opacity: 1;
}


.SwitchButtonIOS .MuiSwitch-root .MuiButtonBase-root.MuiSwitch-switchBase {
    top: 9px;
    left: 8px;
}

.SwitchButtonIOS .MuiSwitch-root .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
    top: 7px;
    left: 8px;
}

.SwitchButtonIOS .MuiSwitch-root .MuiSwitch-thumb {
    width: 13px;
    height: 13px;
    background: #767680;
}

.SwitchButtonIOS .MuiSwitch-root .Mui-checked .MuiSwitch-thumb {
    width: 18px;
    height: 18px;
    background: #fff;
}


.SwitchButtonIOS .MuiSwitch-root .MuiSwitch-track {
    border-radius: 100px;
    background-color: #E2E1EC !important;
    border: #767680 2px solid;
    position: relative;
    opacity: 1 !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: #0450E1 !important;
    border: #0450E1 2px solid;
}