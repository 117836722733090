.TextArea{
    position: relative;
}

.TextArea .CharCount{
    color: rgb(182, 185, 185);
    font-size: 12px;
}


.TextArea textarea{
border: #E4E8EC 1px solid;
border-radius: 4px;
min-height: 40px;
width: 100%;
padding: 2%;
font-size: 17px;
}

.TextArea .HelperTextCounter{
    display: flex;
    justify-content: space-between;
    /* position: absolute;
    right: 0; */
}

