.FieldPasswordC ::placeholder {
    color: #ccc;
    font-size: 16px;
  }

.FieldPassword .custom-input {
    height: 48px;
    border-radius: 8px;
    border: #E4E8EC 1px solid;
    padding: 0 15px;
    width: 94%;
    color: #676C7B;
    font-size: 16px;
    font-family: 'Poppins', sans-serif !important;
}

.FieldPassword {
    position: relative;
    display: flex;
    height: 48px;
    flex-wrap: wrap;
}
.FieldPassword  .icon-span {
    position: absolute;
    right: 10px;
    top: 13px;
}

.FieldPassword  .icon-span svg{
    color: #676C7B;
}

.tracker-box{
    padding-top: 42px;
}

.not-validated{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.validated{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    text-decoration: line-through;
}

.not-validated svg{
    color: #676C7B;
    font-size: 16px;
}



.validated svg{
    color: #4C9F41;
    font-size: 16px;
}

.list-icon{
    margin-right: 10px;
    display: flex;
}

.tracker-box{
    color: #676C7B;
    font-size: 14px;
}

.PasswordStrengthBar {
    margin-left: 2px;
    margin-bottom: 22px;
}

.PasswordStrengthBar p{
    float: left;
    margin-top: 0 !important;
} 

.PasswordStrengthBar div{
    position: relative;
    top: 12px;
} 

.FieldPassword ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #77767A;
  }
  .FieldPassword ::-moz-placeholder { /* Firefox 19+ */
    color: #77767A;
  }
  .FieldPassword :-ms-input-placeholder { /* IE 10+ */
    color: #77767A;
  }
  .FieldPassword :-moz-placeholder { /* Firefox 18- */
    color: #77767A;
  }

  .TextFieldPasword .MuiFormControl-root .MuiInputBase-root ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #77767A;
    opacity: 1;
  }
  .TextFieldPasword ::-moz-placeholder { /* Firefox 19+ */
    color: #77767A;
  }
  .TextFieldPasword :-ms-input-placeholder { /* IE 10+ */
    color: #77767A;
  }
  .TextFieldPasword :-moz-placeholder { /* Firefox 18- */
    color: #77767A;
  }