/* .SlateEditor{
    background: rgb(255, 255, 255);
    padding: 20px;
}

.SlateEditor blockquote{
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}

.SlateEditor :global .MuiDivider-fullWidth{

    margin: 10px 1px;

} */

.SlateEditorTools {
    padding: 11px 0px 3px 0px;
    border-bottom: #D3D8DA 1px solid;
}
.SlateEditor{
    border: #D3D8DA 1px solid;
}

.SlateEditorTextArea{
    padding: 0 16px;
    min-height: 150px;
}

.SlateEditorTextAreaContainer{
    position: relative;
}

.EditCharCounter{
    position: absolute;
    right: 0;
}

.EditCharCounter .FieldCount{
    font-size: 12px;
    color: #676C7B;
}