.MenueDropdownWithImgCont {
    padding: 10px 16px;
}

.MenueDropdownWithImg {
    height: 64px;
}

.MenueDropdownWithImgCont .MuiSelect-select{
    padding: 16px 12px !important;
}


.SlImLst {
    display: flex;
    align-items: center;
    color: #0B132B;
    font-size: 16px;
    font-weight: 500;    
}

.MuiSelect-select .SlImLstlabel{
    max-width: 138px;
    overflow: hidden;
    position: relative;
     
}

.MuiMenu-list .SlImLstlabel{
    max-width:initial !important;
    overflow: visible !important; 
}

.SlImLst .MuiAvatar-root {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    /* background: #fff; */
    border: #e8e8e8 1px solid;
}

.MuiMenuItem-root.SlImLsting {
    border-radius: 8px;
    margin: 0 10px;
    width: 456px;
    padding: 12px;
    height: 64px;
    margin-bottom: 5px;
}

.MenueDropdownWithImg .MuiOutlinedInput-notchedOutline legend {
    display: none;
}

.MenueDropdownWithImg .listUnCheck {
    display: none;
}

.MenueDropdownWithImg .listCheck {
    display: none;
}

.Mui-selected.SlImLsting .listUnCheck {
    display: none;
}

.SlImLsting .listCheck {
    display: none;
}

.SlImLsting .listUnCheck {
    display: block;
    color: #676C7B;
    margin-right: 5px;
}


.Mui-selected.SlImLsting .listCheck {
    color: #004FE0;
    margin-right: 5px;
    display: block;
}

.SlImLsting .listUnBadge {
    position: absolute;
    right: 20px;
}

.MenueDropdownWithImg .listUnBadge {
    display: none;
}

.SlImLstlabel label {
    display: block;
    font-size: 12px;
    color: #676C7B;
}

.SlImLstingTitle {
    padding: 8px 15px;
    color: #676C7B;
    font-size: 12px;
    font-weight: 500;
}

.CreateNewButton {
    display: flex;
    align-items: center;
    margin-left: 50px;
}

.CreateNewButton .CreateNewIcon {
    width: 32px;
    height: 32px;
    text-align: center;
    border: #E4E8EC 1px solid;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #004FE0;
    margin-right: 10px;
}

.CreateNewButton .CreateNewLabel {
    font-size: 16px;
    color: #0B132B;
    font-weight: 500;
}

.CreateNewButtonContainer {
    text-decoration: none;
    color: #0B132B;
    margin-top: 18px;
    display: block;
    margin-bottom: 15px;
}
