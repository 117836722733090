.modalOneMaster{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
  }
  .modalOneMaster .ModalOneContainer {
    background: #fff;
    border-radius: 28px;
    position: absolute;
    outline: none;
    padding: 20px 0 !important;
}
.modalOneMaster .ModalOneContainer .ModalHeader {
  border-bottom: none;
  padding: 0px 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px 28px 0 0;
  font-family: 'Poppins', sans-serif;
}
  
  .modalOneMaster .ModalOneContainer .ModalTitle {
    margin: 0 !important;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #0B132B !important;
  }
  
  .modalOneMaster .ModalOneFooter {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px 0;
    border-radius: 0 0 28px 28px;
}
  
  .modalOneMaster .ModalOneFooter .FooterButton .MuiButtonBase-root{
    margin-right: 15px;
  }
  
  .modalOneMaster .ModalOneFooter .FooterButton .MuiButtonBase-root{
    margin-right: 15px;
    height: 38px;
    box-shadow: none;
    font-size: 14px;
  }
  
  .modalOneMaster .ModalOneFooter .FooterButton .btLight{
    color: #676C7B;
    background: #fff; 
    border: #E4E8EC 1px solid;
    border-radius: 10px !important;
    text-transform: initial !important;
    padding: 10px 15px;
  }
  
  .modalOneMaster .ModalOneFooter .FooterButton .btLight:hover{
    color: #676C7B;
    background: #fff; 
    border: #E4E8EC 1px solid;
  } 
  .modalOneMaster .ModalOneFooter .FooterButton .btnBlue {
    color: #fff;
    background: #004FE0;
    border: #004FE0 1px solid;
    border-radius: 10px !important;
    text-transform: initial !important;
    padding: 10px 15px;
}
  
  .modalOneMaster .ModalOneFooter .FooterButton .btnBlue:hover{
    color: #fff;
    background: #004FE0; 
    border: #004FE0 1px solid;
  }
  
  .modalOneMaster .ModalOneBody {
    padding: 16px 42px;
    overflow-y: auto;
    max-height: 700px;
}

.ModaClose{
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

@media (max-width: 1400px) {
  .modalOneMaster .ModalOneBody {
    max-height: 300px;
}
}
 

@media (max-width: 1100px) {
  .modalOneMaster .ModalOneBody {
    max-height: 290px;
}
}

@media (max-width: 768px) {
  .modalOneMaster .ModalOneContainer {
    width: 90% !important;
}

.modalOneMaster .ModalOneBody {
  padding: 16px 23px;
}
}
  
  