.PhoneFieldManContainer{}

.PhoneFieldManContainer .input_label {
    display: block;
    font-size: 14px !important;
    color: #676C7B;
    margin-bottom: 4px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    height: 18px;
}

.PhoneFieldManContainer .react-tel-input .country-list .country{
    padding: 12px 9px 13px 46px !important;
}

.PhoneFieldManContainer .underlineInput .react-tel-input .special-label{
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.54) !important;
    margin-left: 0px !important;
}

.PhoneFieldManContainer .react-tel-input .form-control:focus{
    border-color: #E4E8EC !important;
    box-shadow: none !important;
}

.PhoneFieldManContainer .react-tel-input .form-control{
    border: 1px solid #E4E8EC !important;
    padding: 18.5px 14px 18.5px 75px !important;
    min-height: 48px !important;
    font-family: 'Poppins', sans-serif !important;
}

.PhoneFieldManContainer .react-tel-input .selected-flag{
    outline: none !important;
    position: relative !important;
    width: 60px !important;
    padding:  0 0 0 11px !important;
    border-radius: 3px 0 0 3px !important;
    border-right: #E4E8EC 1px solid !important;
}

.PhoneFieldManContainer .react-tel-input .special-label{
    display: none !important;
}

.PhoneFieldManContainer .react-tel-input .form-control:hover{
    border-color: #E4E8EC !important;
}

.PhoneFieldManContainer .error {
    display: flex !important;
    letter-spacing: 0.4 !important;
    color: red !important;
    font-size: 12px !important;
    text-indent: 5px !important;
    margin-left: 5 !important;
    position: absolute !important;
    margin-top: 4px !important;
  }

  .contactFormContainer .PhoneFieldManContainer .react-tel-input .special-label{
    display: none !important;
    color: #979797 !important;
}

.error .PhoneFieldManContainer .react-tel-input .form-control{
    border-color: #f44336 !important;
}

.error .PhoneFieldManContainer .react-tel-input .selected-flag{
    border-color: #f44336 !important;
}




